import a from '../GhostPartner/a.png';
import b from '../GhostPartner/b.png';
import c from '../GhostPartner/c.png';
import d from '../GhostPartner/d.png';
import e from '../GhostPartner/e.png';
import f from '../GhostPartner/f.png';
import g from '../GhostPartner/g.png';
import h from '../GhostPartner/h.png';

const PartnerArray = [
    {
        img: a
    },
    {
        img: b
    },
    {
        img: c
    },
    {
        img: d
    },
    {
        img: e
    },
    {
        img: f
    },
    {
        img: g
    },
    {
        img: h
    },
];

export default PartnerArray;