import React, { useEffect, useState } from 'react'
import '../assets/css/ButtonStyle.css'
import '../assets/css/Navbar.css'
import GhostSolana from '../assets/Images/Ghost solana.png'
import { toast } from 'react-toastify'

export default function Navbar() {

    const [opacityValue, setOpacityValue] = useState(0)
    const [windowScrollValue, setWindowScrollValue] = useState(window.scrollY)
    
    const handleScroll = (() => {
        setWindowScrollValue(window.scrollY)
        if (windowScrollValue > 10) {
            setOpacityValue(windowScrollValue / 100);

            if (opacityValue > 1) {
                setOpacityValue(1)
            }
            else {
                setOpacityValue(windowScrollValue / 100)
            }
        }
        else {
            setOpacityValue(0)
        }
    })

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        const button = document.querySelector(".button");
        button.addEventListener("click", (e) => {
            button.classList.add("animate");
            setTimeout(() => {
                button.classList.remove("animate");
            }, 2000);
        });


        return (() => {
            window.removeEventListener("scroll", handleScroll)
        })
    }, [windowScrollValue])

    const inputValue = "Fkz2zP4NnbAH2wAKMC9QUWkAX9Kdfmi1EuWewr7FwVZZ";
    const handleCopyText = (e) => {
        e.preventDefault()
        var copyText = document.querySelector('#token');
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        toast.success("Copied!!", {
            position: "top-center",
            hideProgressBar: true,
            autoClose: 2000,
            theme: "light",
            });

      };

    return (
        <>
        <div className=' navigationbar' style={{background: `rgba(24, 24, 24, ${opacityValue})`, zIndex:"9999", boxShadow: `0px 3px 20px 10px rgb(24, 24, 24, .6)`}}>
            <section className='container'>
                <div className='Header_space'>
                    <div className='NavBar'>
                        <img src={GhostSolana} alt="" />
                        <div className='Token'>
                            <input type="text" id='token' disabled={true} value={inputValue} />
                            <a href="" className='btn-primary button' onClick={handleCopyText}>Copy</a>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </>
    )
}
