import React from 'react';
import PartnerArray from '../assets/data/GhostPartners';

export default function Partners() {
    return (
        <>
            <section className='partner-section container common-spacing pt-0'>
                <h1><b>Ghost</b> Partners</h1>
                <div className='Partners'>
                    {PartnerArray.map((elem, index) => (
                        <div className='partner-card' key={index}>
                            <img src={elem.img} alt="" />
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}
