import React from 'react'
import '../assets/css/Footer.css'

export default function Footer() {
  return (
    <>
      <section className='container-fluid p-0'>
        <div className='footer'>
          <h1><a href="#"><b>Ghost Solana.</b></a> 2024 All Rights Reserved.</h1>
        </div>
      </section>
    </>
  )
}
