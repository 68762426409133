import React, { useState, useEffect } from 'react'
import './assets/css/Home.css'
import About from './components/About'
import Tokenomics from './components/Tokenomics'
import RoadMap from './components/RoadMap'
import GhostingOn from './components/GhostingOn'
import Partners from './components/Partners'

export default function Home() {

  const [mobile, setMobile] = useState(true);
  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth < 642) {
        setMobile(true);
      }
      else {
        setMobile(false)
      }
    }

    if (window.innerWidth < 642) {
      setMobile(true);
    }
    else {
      setMobile(false)
    }

    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    }
  }, [])

  return (
    <>
      <section className='container common-spacing'>
        <div className='hero'>
          <div className='hero-left'>
            <div>
              <h1 className="wow fadeInDown start_animation animated">
                <strong className="text-bold">Adding</strong> Ghostly <br /><strong>Touch </strong>
                To<br />Solana <strong>memes! </strong>
              </h1>
              <div className='d-flex gap-4'>
                <a href="#" target='_blank' rel="noreferrer noopener" className='btn-primary'>Dextools</a>
                <a href="https://t.me/ghostsolanacoin/3" target='_blank' rel="noreferrer noopener" className='btn-primary'>Airdrop</a>
              </div>
            </div>
          </div>
          <div className='hero-right'>
            <div className='right-box'>
              <a className='btn-quadratic' href='https://solsale.app/presale/BdFHmDf5tfrMuB7bxfQFkubPufn7WNL1ctwM9B4rDmrf' target='_blank' rel="noreferrer noopener">
                <strong>BUY</strong>Ghost Solana
              </a>
              <p className='text-center'>
                <span className="text-white">Total Supply : <strong>1 Billion</strong></span>
                <span className="text-white">Token Price : <strong>1 SOL = 300K GS</strong></span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <About />
      <Tokenomics/>
      <RoadMap mobile={mobile}/>
      <GhostingOn />
      <Partners />
    </>
  )
}
