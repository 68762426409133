import React from 'react';
import Marquee from "react-fast-marquee";
import MarqueeArray from '../assets/data/Carousel';

export default function GhostingOn() {
    return (
        <>
            <section className='GhostingOn common-spacing container-fluid'>
                <div>
                    <h1><b>Ghosting</b> On</h1>
                    <Marquee>
                        {MarqueeArray.slice(0, MarqueeArray.length / 2).map((elem, index) => (
                            <div className='partner-card' key={index}>
                                <img src={elem.img} alt="" />
                            </div>
                        ))}
                    </Marquee>
                    <div className='w-100 my-4 my-md-5 '></div>
                    <Marquee direction='right'>
                        {MarqueeArray.slice(MarqueeArray.length / 2, MarqueeArray.length).map((elem, index) => (
                            <div className='partner-card' key={index}>
                                <img src={elem.img} alt="" />
                            </div>
                        ))}
                    </Marquee>
                </div>
            </section>
        </>
    )
}
