import { useEffect } from 'react';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './Home'

function App() {

  useEffect(()=>{
    window.scrollTo({top: 0, behaviour: "smooth"})
  },[])

  return (
    <>

    <a className='btn-sticky' href='https://t.me/ghostsolanacoin' target='_blank' rel="noreferrer noopener" style={{bottom:"75px"}}>
      <i className="bi bi-telegram"></i>
    </a>
    <a className='btn-sticky' href='https://www.twitter.com/ghostsolanacoin' target='_blank' rel="noreferrer noopener">
      <i className="bi bi-twitter-x"></i>
    </a>
      <Navbar />
      <Home />
      <Footer />
    </>
  );
}

export default App;
