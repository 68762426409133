import React from 'react';
import SkullCoin from '../assets/Images/Skull-coin.png'

export default function Tokenomics() {
    return (
        <>
            <section className='common-spacing Tokenomics pt-0'>
                <div className='container'>
                    <h1>Tokenomics</h1>
                        <div className='content'>
                        <div>
                            <div className='box-1'>
                                <div className="box-2">
                                    <p>
                                    <span>Total Supply 1.000.000.000</span> <br />
                                    Ghost Solana Token
                                    </p>
                                </div>
                            </div>
                            <div className='box-1'>
                                <div className="box-2">
                                <p>
                                <span>Liquidity</span> <br />BURNED
                                </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='box-1'>
                                <div className="box-2">
                                <p>
                                    0% <br /><span>Taxes</span>
                                    </p>
                                </div>
                            </div>
                            <div className='box-1'>
                                <div className="box-2">
                                <p>
                                    <span>Mint & Freeze </span> <br /> REVOKED
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='Center'>
                            <div className='arrow-left'></div>
                            <div className='round-center'>
                                <img src={SkullCoin} alt="" />
                            </div>
                            <div className='arrow-right'></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
