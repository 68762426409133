import React from 'react'
import roadmap from '../assets/Images/roadMap.jpg'
import roadMapMobile from '../assets/Images/roadMapMobile.jpg'

export default function RoadMap({mobile}) {
  return (
    <>
        <section className='Roadmap container-fluid px-0'>
            <h1>Roadmap</h1>
            <img src={mobile ? roadMapMobile : roadmap } alt="" />
        </section>
    </>
  )
}
