import React from 'react';
import StadingSkull from '../assets/Images/Standing skull.svg'

export default function About() {
    return (
        <>
            <section className='about common-spacing'>
                <div className='container p-md-0'>
                    <div className='contetnt'>
                        <div className="contetnt-left">
                            <img src={StadingSkull} alt="" />
                        </div>
                        <div className="contetnt-right">
                            <h1>
                                WHAT <span> IS</span> <br /> <b> GHOST</b> SOLANA ?
                            </h1>
                            <p>
                                Welcome to Ghost Solana, the epitome of meme coins on the Solana blockchain. Our vibrant and inclusive community owns Ghost Solana entirely. We enthusiastically embrace diverse perspectives as we work together to establish Ghost Solana as the leading community in the cryptocurrency world.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
