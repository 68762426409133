import Slerf from '../partners/Slerf.png'
import Arbdoge from '../partners/Arbdoge Ai.png'
import Babybog from '../partners/Babybog.png'
import solama from '../partners/solama.png'
import Pepe from '../partners/Pepe.png'
import Shiba from '../partners/Shiba.png'
import dogwifhat from '../partners/dogwifhat.png'
import doge from '../partners/doge.png'
import bome from '../partners/bome.png'
import Bonk from '../partners/Bonk.png'
import CatInDogsWorls from '../partners/Cat in dogs worls.png'
import DogeEatDoge from '../partners/Doge Eat Doge.png'
import DogelonMars from '../partners/Dogelon mars.png'
import FLOKI from '../partners/FLOKI.png'
import MiladyMemeCoin from '../partners/Milady Meme Coin.png'
import Pepe1 from '../partners/Pepe-1.png'
import Turbo from '../partners/Turbo.png'
import Wen from '../partners/Wen.png'


const MarqueeArray = [
    {
        img: Slerf
    },
    {
        img: Arbdoge
    },
    {
        img: Babybog
    },
    {
        img: solama
    },
    {
        img: Pepe
    },
    {
        img: Shiba
    },
    {
        img: dogwifhat
    },
    {
        img: doge
    },
    {
        img: bome
    },
    {
        img: Bonk
    },
    {
        img: CatInDogsWorls
    },
    {
        img: DogeEatDoge
    },
    {
        img: DogelonMars
    },
    {
        img: FLOKI
    },
    {
        img: MiladyMemeCoin
    },
    {
        img: Pepe1
    },
    {
        img: Turbo
    },
    {
        img: Wen
    },
];

export default MarqueeArray ;
